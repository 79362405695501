<template>
  <div class="content_body ReportCustomerAccountStatistics" style="padding:15px" v-loading="loading">
    <!-- 筛选 -->
    <div class="nav_header" style="padding:0 0 15px 0px">
      <el-form :inline="true" size="small" :model="searchCusAccountData" @submit.native.prevent>
        <el-form-item label="顾客姓名/编号">
          <el-input v-model="searchCusAccountData.Name" clearable @keyup.enter.native="handleCusAccountSearch"
            @clear="handleCusAccountSearch" placeholder="请输入姓名/编号"></el-input>
        </el-form-item>
        <el-form-item v-if="EntityAllEntity.length>1" label="购买门店">
          <el-select v-model="searchCusAccountData.BuyEntityID" clearable filterable placeholder="请选择门店"
            :default-first-option="true" @change="handleCusAccountSearch">
            <el-option v-for="item in EntityAllEntity" :key="item.ID" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="storeEntityList.length>1" label="所属门店">
          <el-select v-model="searchCusAccountData.EntityID" clearable filterable placeholder="请选择门店"
            :default-first-option="true" @change="handleCusAccountSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="顾客等级">
          <el-select v-model="searchCusAccountData.LevelID" clearable filterable placeholder="请选择顾客等级"
            :default-first-option="true" @change="handleCusAccountSearch">
            <el-option v-for="(item,index) in customerLevel" :key="index" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select v-model="searchCusAccountData.GoodsTypeName" clearable filterable placeholder="请选择卡类型"
            :default-first-option="true" @change="handleCusAccountSearch">
            <el-option label="项目" value="项目"></el-option>
            <el-option label="储值卡" value="储值卡"></el-option>
            <el-option label="时效卡" value="时效卡"></el-option>
            <el-option label="通用次卡" value="通用次卡"></el-option>
            <el-option label="产品" value="产品"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="packageCardAll.length>0" label="所属套餐卡">
          <el-select v-model="searchCusAccountData.PackageCardID" clearable filterable placeholder="请选择套餐卡"
            :default-first-option="true" @change="handleCusAccountSearch">
            <el-option v-for="(item,index) in packageCardAll" :key="index" :label="item.Name" :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品类别">
          <el-input v-model="searchCusAccountData.CategoryName" clearable @keyup.enter.native="handleCusAccountSearch"
            @clear="handleCusAccountSearch" placeholder="请输入商品类别"></el-input>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input v-model="searchCusAccountData.GoodsName" clearable @keyup.enter.native="handleCusAccountSearch"
            @clear="handleCusAccountSearch" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="品牌">
          <el-input v-model="searchCusAccountData.BrandName" clearable @keyup.enter.native="handleCusAccountSearch"
            @clear="handleCusAccountSearch" placeholder="请输入商品品牌"></el-input>
        </el-form-item>
        <el-form-item label="是否赠送">
          <el-select v-model="searchCusAccountData.IsLargess" clearable filterable placeholder="请选择是否赠送"
            :default-first-option="true" @change="handleCusAccountSearch">
            <el-option label="是" value="true"></el-option>
            <el-option label="否" value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否失效">
          <el-select v-model="searchCusAccountData.IsOverdue" clearable filterable placeholder="请选择卡是否过期"
            :default-first-option="true" @change="handleCusAccountSearch">
            <el-option label="是" value="false"></el-option>
            <el-option label="否" value="true"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleCusAccountSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="CusAccountExport" type="primary" size="small" :loading="downloadLoading"
            @click="downloadCusAccountExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <el-table size="small" show-summary :summary-method="getCusAccountSummaries" :data="result.list"
      v-loading="CusAccountLoading">
      <el-table-column prop="Name" label="顾客姓名"></el-table-column>
      <el-table-column prop="PhoneNumber" label="手机号"></el-table-column>
      <el-table-column prop="BuyEntityName" label="购买门店"></el-table-column>
      <el-table-column prop="EntityName" label="所属门店"></el-table-column>
      <el-table-column prop="LevelName" label="顾客等级"></el-table-column>
      <el-table-column prop="GoodsTypeName" label="商品类型"></el-table-column>
      <el-table-column prop="PackageCardName" label="所属套餐卡"></el-table-column>
      <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
      <el-table-column prop="GoodsName" label="商品名称"></el-table-column>
      <el-table-column prop="BrandName" label="商品品牌"></el-table-column>
      <el-table-column prop="IsLargess" label="是否赠送" :formatter="formatIsLargess"></el-table-column>
      <el-table-column prop="Quantity" align="right" label="购买数量"></el-table-column>
      <el-table-column prop="TotalAmount" align="right" label="总金额">
        <template slot-scope="scope">
          {{scope.row.TotalAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="Balance" align="right" label="剩余数量"></el-table-column>
      <el-table-column prop="BalanceAmount" align="right" label="剩余金额">
        <template slot-scope="scope">
          {{scope.row.BalanceAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="PayAmount" align="right" label="本金剩余">
        <template slot-scope="scope">
          {{scope.row.PayAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="LargessAmount" align="right" label="赠金剩余">
        <template slot-scope="scope">
          {{scope.row.LargessAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="ArrearAmount" align="right" label="剩余欠款">
        <template slot-scope="scope">
          {{scope.row.ArrearAmount | NumFormat}}
        </template>
      </el-table-column>
      <el-table-column prop="BuyDate" align="right" label="购买时间"></el-table-column>
      <el-table-column prop="ValidDate" align="right" label="失效日期"></el-table-column>
      <el-table-column prop="IsOverdue" align="right" label="是否失效" :formatter="formatIsOverdue"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination background v-if="CusAccountPaginations.total > 0" @current-change="handleCusAccountPageChange"
        :current-page.sync="CusAccountPaginations.page" :page-size="CusAccountPaginations.page_size"
        :layout="CusAccountPaginations.layout" :total="CusAccountPaginations.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import API from "@/api/Report/Customer/accountStatistics";
import APICustomerLevel from "@/api/CRM/Customer/customerLevel";
import EntityAPI from "@/api/Report/Common/entity";
import permission from "@/components/js/permission.js";

export default {
  name: "ReportCustomerAccountStatistics",

  components: {},

  directives: {},

  data() {
    return {
      loading: false,
      customerLevel: [], //顾客等级
      storeEntityList: [], //权限下所属门店
      EntityAllEntity: [], //购买门店
      packageCardAll: [], //套餐卡
      // 搜索条件
      searchCusAccountData: {
        Name: "",
        BuyEntityID: null,
        EntityID: null,
        LevelID: null,
        GoodsTypeName: null,
        GoodsName: null,
        PackageCardID: null,
        CategoryName: null,
        BrandName: null,
        IsLargess: null,
        IsOverdue: null,
      },
      // 搜索结果
      result: {
        totalForm: {},
        list: [],
      },
      CusAccountExport: false, //导出权限
      saleTreatDetailExport: true,
      downloadLoading: false,
      CusAccountLoading: false,
      CusAccountPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },

  mounted() {
    const that = this;
    //   导出权限
    that.CusAccountExport = permission.permission(
      that.$route.meta.Permission,
      "Report-Customer-AccountStatistics-Export"
    );
    // 等级
    that.CustomerLevelData();
    //获得当前用户下的权限门店
    that.getstoreEntityList();
    // 获取购买门店
    that.getEntityAllEntity();
    // 获取套餐卡
    that.getpackageCardAll();
    // 搜索
    that.handleCusAccountSearch();
  },

  methods: {
    formatIsLargess(row) {
      if (row.IsLargess) {
        return "赠送";
      } else {
        return "非赠送";
      }
    },
    formatIsOverdue(row) {
      if (row.IsOverdue) {
        return "未失效";
      } else if (row.IsOverdue === false) {
        return "已失效";
      } else {
        return "";
      }
    },
    // 顾客等级
    CustomerLevelData: function () {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      APICustomerLevel.getCustomerLevel(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.customerLevel = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取购买门店
    getEntityAllEntity() {
      var that = this;
      API.allEntity()
        .then((res) => {
          if (res.StateCode == 200) {
            that.EntityAllEntity = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取套餐卡
    getpackageCardAll() {
      var that = this;
      API.packageCardAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardAll = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 搜索
    handleCusAccountSearch() {
      var that = this;
      that.CusAccountPaginations.page = 1;
      that.getAccountStatistics();
    },
    // 顾客余量列表
    getAccountStatistics() {
      const that = this;
      that.CusAccountLoading = true;
      const params = {};
      Object.assign(params, that.searchCusAccountData);
      (params.PageNum = that.CusAccountPaginations.page),
        API.accountStatistics(params)
          .then((res) => {
            console.log(res);
            if (res.StateCode == 200) {
              that.result.totalForm =
                res.Data.customerAccountStatisticsSumStatementForm;
              that.result.list = res.Data.detail.List;
              that.CusAccountPaginations.total = res.Data.detail.Total;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function () {
            that.CusAccountLoading = false;
          });
    },
    // 导出
    downloadCusAccountExcel() {
      var that = this;
      const params = {};
      Object.assign(params, that.searchCusAccountData);
      that.downloadLoading = true;
      API.accountStatisticsExcel(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "顾客余量统计.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function () {
          that.downloadLoading = false;
        });
    },
    // 合计
    getCusAccountSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = <span class="font_weight_600">合计</span>;
          return;
        }
        var filter_NumFormat = this.$options.filters["NumFormat"];
        switch (column.property) {
          case "Quantity":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.Quantity}
              </span>
            );
            break;
          case "TotalAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.TotalAmount)}
              </span>
            );
            break;
          case "Balance":
            sums[index] = (
              <span class="font_weight_600">
                {this.result.totalForm.Balance}
              </span>
            );
            break;
          case "BalanceAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.BalanceAmount)}
              </span>
            );
            break;
          case "PayAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.PayAmount)}
              </span>
            );
            break;
          case "LargessAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.LargessAmount)}
              </span>
            );
            break;
          case "ArrearAmount":
            sums[index] = (
              <span class="font_weight_600">
                {filter_NumFormat(this.result.totalForm.ArrearAmount)}
              </span>
            );
            break;
          default:
            sums[index] = <span class="font_weight_600"></span>;
        }
      });
      return sums;
    },
    // 分页
    handleCusAccountPageChange() {
      this.getAccountStatistics();
    },
  },
};
</script>

<style lang="scss">
.ReportCustomerAccountStatistics {
}
</style>